/* eslint-disable */
import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, LinearProgress, MenuItem, Select, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import FileUpload from "react-material-file-upload";
import { useNavigate, useParams } from "react-router-dom";
import Iconify from "src/components/Iconify";
import Page from "src/components/Page";
import { localdata } from "src/service/localdata";
import Services from "src/service/Services";
import FileRIghtCLick from "./components/modal";

import './File.scss';

const Files = () => {
    const [filesList, setFiles] = useState([]);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [breadCrumbs, setBreadcrumbs] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [files, setFile] = useState([]);
    const [selected, setSelected] = useState({});
    const [newFile, setNewFile] = useState({});
    const [access, setAccess] = useState({});
    const [error, setError] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const userData = localdata.GET_DATA();

    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    useEffect(() => {
        refresh();
        if (id) {
            updateBreadcrumbs(id);
        } else {
            setBreadcrumbs([])
        }
    }, [id]);

    const deleteFile = (id, file) => {
        setLoading(true);
        axios.delete(Services.FILES(id), {
            params: { file },
            headers: {
                Authorization: `Bearer ${userData.token}`,
            }
        }).then((res) => {
            refresh();
        }).finally(() => {
            setLoading(false);
        });
    }

    const refresh = () => {
        setLoading(true);
        axios.get(Services.FILES(id), {
            headers: {
                Authorization: `Bearer ${userData.token}`,
            }
        }).then((res) => {
            setFiles(res.data.files);
        }).finally(() => {
            setLoading(false);
        });
    }

    const updateBreadcrumbs = (id) => {
        let isCheck = true;
        setBreadcrumbs([...breadCrumbs.filter((data) => {
            if (data.id === id) {
                isCheck = false;
                return true;
            }
            return isCheck;
        })])
    }

    const accessFile = async (access) => {
        axios.post(`${Services.FILES()}/access/${access.id}`, {
            password: access.password
        }, {
            headers: {
                Authorization: `Bearer ${userData.token}`,
            }
        }).then(() => {
            setBreadcrumbs([...breadCrumbs, access.file])
            navigate(`/dashboard/files/${access.id}`)
            setOpen2(false);
        }).catch(() => {
            setOpen2(false);
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 3000);
        })
    }

    const addFile = async () => {
        setUploadPercentage(1);
        let data = {
            ...newFile,
            isProtected: !!newFile.password,
            parentId: id,
            createdBy: localdata.GET_DATA().id
        };
        if (files.length) {
            data.file = await toBase64(files[0]);
            data.size = files[0].size
        }
        axios.post(Services.FILES(), data, {
            headers: {
                Authorization: `Bearer ${userData.token}`,
            },
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                const percent = Math.round((loaded * 100) / total);
                setUploadPercentage(percent);
              },
        }).then(() => {
            refresh();
            setOpen(false);
            setNewFile({});
            setFile([]);
        }).finally(() => {
            setUploadPercentage(0);
        })
    }

    // Right Click
    const [context, setContext] = useState(false);
    const [xYPosistion, setXyPosistion] = useState({ x: 0, y: 0 });
    const showNav = (event, file) => {
        event.preventDefault();
        setContext(false);
        const positionChange = {
            x: event.pageX,
            y: event.pageY,
        };
        setSelected(file);
        setXyPosistion(positionChange);
        setContext(true);
    };
    const hideContext = (event) => {
        setContext(false);
    };
    const [chosen, setChosen] = useState();
    const initMenu = (chosen) => {
        setChosen(chosen);
    };

    return (
        <Page title="Files">
            {error && (
                <Alert variant="filled" severity="error" style={{ position: 'absolute', top: 100, right: 10, zIndex: 999 }}>
                    <AlertTitle>Error</AlertTitle>
                    Invalid Password <strong>Please check your password</strong>
                </Alert>
            )}
            <div className="file-list container-fluid section-t-space background-auto px-4 py-4 rounded" onClick={hideContext}>
                <div className="page-content d-flex justify-content-between">
                    <div className="content-center">
                        <h2>Files</h2>
                    </div>
                    <div className="add-icon" onClick={() => {
                        setOpen(true)
                    }}>
                        <Iconify icon="eva:plus-fill" />
                    </div>
                </div>
                <div className="d-flex theme-form mt-2 breadcrumbs">
                    <label className="h6 me-3 ">Location :-</label>
                    <a href="javascript:void()" onClick={() => {
                        navigate('/dashboard/files/')
                        setBreadcrumbs([]);
                    }}>
                        <label className="text-primary h6">Home</label>
                    </a>
                    {
                        breadCrumbs.map((breadCrumbs, index) => {
                            return (
                                <>
                                    <a href="javascript:void()" onClick={() => {
                                        navigate(`/dashboard/files/${breadCrumbs.id}`)
                                        updateBreadcrumbs(breadCrumbs.id)
                                    }}>
                                        <label className="text-primary h6">/{breadCrumbs.name} </label>
                                    </a>
                                </>
                            )
                        })
                    }
                </div>
                <div className="row theme-form">
                    {
                        filesList.map((file) => {
                            return (
                                <div className="col-6 col-sm-4 col-md-3 col-lg-2 text-left file-content"
                                    onContextMenu={(e) => showNav(e, file)}
                                >
                                    <img src={file.type === 'folder'
                                        ? "/static/folder.svg"
                                        : "/static/pdf.svg"
                                    } className="file-image" alt="favourite" onClick={() => {
                                        if (file.type === 'folder') {
                                            if (!file.isProtected) {
                                                setBreadcrumbs([...breadCrumbs, file])
                                                navigate(`/dashboard/files/${file.id}`)
                                            } else {
                                                setAccess({ ...access, file, id: file.id })
                                                setOpen2(true);
                                            }

                                        } else {
                                            window.open(file.url, '_new');
                                        }
                                    }} />
                                    <label className="text-left file-name w-100">{file.name}</label>
                                </div>
                            )
                        })
                    }
                    {
                        !isLoading && !filesList.length && (
                            <div className="text-center mt-5">
                                <label>No records Found</label>
                            </div>
                        )
                    }
                </div>
            </div>

            {context && (
                <FileRIghtCLick
                    id={selected.id}
                    type={selected.type}
                    selectedUserData={selected}
                    RefreshData={refresh}
                    isOpen={context}
                    xYPosistion={xYPosistion}
                    deleteFile={deleteFile}
                />
            )}
            {/* Add Post */}

            <Dialog
                open={isOpen}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Add File</DialogTitle>
                <DialogContent>
                    <DialogContentText className='my-3' style={{ width: '500px', minWidth: '100%', maxWidth: '100%' }} id="alert-dialog-description">
                        <div className="row">
                            <div className="col-md-6">
                                <FormControl className="w-100 mb-3">
                                    <InputLabel id="demo-simple-select-label">File Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={newFile.type}
                                        onChange={({ target: { value } }) => setNewFile({ ...newFile, type: value })}
                                        label="File Type"
                                    >
                                        <MenuItem value={'folder'}>Folder</MenuItem>
                                        <MenuItem value={'file'}>File</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-md-6">
                                <TextField
                                    className="w-100 mb-3"
                                    size="large"
                                    label="File Name"
                                    value={newFile.name}
                                    onChange={({ target: { value } }) => setNewFile({ ...newFile, name: value })}
                                />
                            </div>
                            {
                                newFile.type === 'folder' && <div className="col-md-12">
                                    <TextField
                                        className="w-100 mb-3"
                                        size="large"
                                        label="Password (Optinal)"
                                        value={newFile.password}
                                        onChange={({ target: { value } }) => setNewFile({ ...newFile, password: value })}
                                    />
                                </div>
                            }
                            {
                                newFile.type === 'file' && <div className="col-md-12">
                                    <FileUpload multiple={false} maxFiles={1} accept="application/pdf" value={files} onChange={setFile} />
                                    {!!uploadPercentage && <LinearProgress className="mt-3" variant="indeterminate" value={uploadPercentage} valueBuffer={uploadPercentage} />}
                                </div>
                            }

                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ margin: 10 }}>
                    <Button onClick={() => setOpen(false)} variant="outlined" style={{ marginRight: 10 }}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            addFile();
                        }}
                        disabled={!(newFile.type && newFile.name) || !!uploadPercentage}
                        autoFocus
                        variant="contained"
                    >
                        Add
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={open2}
                onClose={() => setOpen2(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Access Denied</DialogTitle>
                <DialogContent>
                    <DialogContentText className='my-3' id="alert-dialog-description">
                        <TextField
                            size="large"
                            label="Password"
                            value={access.password}
                            onChange={(e) => setAccess({ ...access, password: e.target.value })}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ margin: 10 }}>
                    <Button onClick={() => setOpen2(false)} variant="outlined" style={{ marginRight: 10 }}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            accessFile(access);
                        }}
                        autoFocus
                        variant="contained"
                    >
                        Access
                    </Button>
                </DialogActions>
            </Dialog>
        </Page>
    )
}

export default Files;