import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';

// material
import {
    Menu,
    MenuItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    Modal,
    TextField,
    Box,
    Typography,
    Stack,
    FormControl,
    InputLabel,
} from '@mui/material';
// component
import Iconify from '../../../components/Iconify';
import Services from '../../../service/Services';
import { localdata } from '../../../service/localdata';

// ----------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    // boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

export default function FileRIghtCLick({
    id,
    type,
    selectedUserData,
    deleteFile,
    xYPosistion,
    RefreshData,
}) {
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);

    const [editedData, setEditedData] = useState(selectedUserData);

    const updateFile = (id, data) => {
        const userData = localdata.GET_DATA();
        axios.put(Services.FILES(id), { ...data }, {
            headers: {
                Authorization: `Bearer ${userData.token}`,
            }
        }).then((res) => {
            RefreshData();
        });
    }

    return (
        <>
            <div className='right-click' style={{ top: xYPosistion.y, left: xYPosistion.x }}>
                <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }} onClick={() => setOpen2(true)}>
                    <ListItemIcon>
                        <Iconify icon="eva:edit-outline" width={24} height={24} />
                    </ListItemIcon>
                    <ListItemText primary="Rename" primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
                {type === 'folder' && <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }} onClick={() => setOpen3(true)}>
                    <ListItemIcon>
                        <Iconify icon="eva:lock-outline" width={24} height={24} />
                    </ListItemIcon>
                    <ListItemText primary="Password" primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
                }
                <MenuItem sx={{ color: 'text.secondary' }} onClick={() => setOpen(true)}>
                    <ListItemIcon>
                        <Iconify icon="eva:trash-2-outline" width={24} height={24} />
                    </ListItemIcon>
                    <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
            </div>

            <Dialog
                open={open2}
                onClose={() => setOpen2(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Rename</DialogTitle>
                <DialogContent>
                    <DialogContentText className='my-3' id="alert-dialog-description">
                        <TextField
                            size="large"
                            label="File Name"
                            value={editedData.name}
                            onChange={(e) => setEditedData({ ...editedData, name: e.target.value })}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ margin: 10 }}>
                    <Button onClick={() => setOpen2(false)} variant="outlined" style={{ marginRight: 10 }}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            updateFile(id, {
                                name: editedData.name
                            })
                            setOpen2(false);
                        }}
                        autoFocus
                        variant="contained"
                    >
                        Update
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={open3}
                onClose={() => setOpen3(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Set Password</DialogTitle>
                <DialogContent>
                    <DialogContentText className='my-3' id="alert-dialog-description">
                        <TextField
                            size="large"
                            label="File Password"
                            type="password"
                            value={editedData.password}
                            onChange={(e) => setEditedData({ ...editedData, password: e.target.value })}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ margin: 10 }}>
                    <Button onClick={() => setOpen3(false)} variant="outlined" style={{ marginRight: 10 }}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            updateFile(id, {
                                password: editedData.password || '',
                                isProtected: !!editedData.password
                            })
                            setOpen3(false);
                        }}
                        autoFocus
                        variant="contained"
                    >
                        Update
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete File</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this File?
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ margin: 10 }}>
                    <Button onClick={() => setOpen(false)} variant="outlined" style={{ marginRight: 10 }}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            deleteFile(id, selectedUserData.url);
                            setOpen(false);
                        }}
                        autoFocus
                        variant="contained"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
